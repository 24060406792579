import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
//import banner1 from '../assets/banner1.jpg'
//import banner2 from '../assets/banner2.jpg'
//import banner3 from '../assets/banner3.jpg'

import banner1 from '../assets/contactus.png'
import banner2 from '../assets/aboutus.png'
import banner3 from '../assets/services.png'

import experience from '../assets/experience-thumb.jpg'
import overview from '../assets/overview-thumb.jpg'
import solutions from '../assets/solutions-thumb.jpg'
import training from '../assets/training-thumb.jpg'

const Home = () =>{
    return (
        <>
            <div className="image-container">
                <Carousel>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={banner1}
                        alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={banner2}
                        alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src={banner3}
                        alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>
            

            <div className="maincontent">
				<div className="container">
					<main id="content" role="main" className="colfull">
						
						<div id="system-message-container"></div>

						<div className="blog-featured">
                            <div className="items-leading clearfix">
			                    <div className="leading-0 clearfix">
			
                                    <h2>Welcome To MobiAI Solutions</h2>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <div className="home-box">
                                                <div className="home-thumb"><img src={overview} alt="overview-thumb"/></div>
                                                <div className="home-content">
                                                    <h6>Overview</h6>
                                                    <p>At MobiAI Solutions, our motive is to provide utmost customer satisfaction by providing cost- effective solutions and add value to the clients.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <div className="home-box">
                                            <   div className="home-thumb"><img src={experience} alt="experience-thumb"/></div>
                                                <div className="home-content">
                                                    <h6>Experience</h6>
                                                    <p>Our Technology Center allows our customers to focus on their core competencies while benefiting from world-class development services.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <div className="home-box">
                                                <div className="home-thumb"><img src={solutions} alt="solutions-thumb"/></div>
                                                <div className="home-content">
                                                    <h6>Solutions</h6>
                                                    <p>Our consultants have expertise in a range of technical areas like Data Management, Data Warehousing and Business Intelligence.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <div className="home-box">
                                                <div className="home-thumb"><img src={training} alt="training-thumb"/></div>
                                                <div className="home-content">
                                                    <h6>Training</h6>
                                                    <p>We provide best hands on online training with real time examples to make sure that the participants are able to handle real time scenario.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                               
                    </main>
                </div> 
            </div>













        </>
    )
}

export default Home

import React from 'react'

import backGroundAbout from "../assets/about-banner.jpg";

const About = () =>{

    const bgStyles = {
        backgroundImage: `url(${backGroundAbout})`,
    }
    return (

        <div id="maincontent">
            <div id="inner-banner margin-tp-btm-50">
                <div className="moduletable">
                    <div className="custom">
                        <div className="page_title about-banner" style={bgStyles}>
                        <div className="container">
                            <h1>About Us</h1>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="maincontent">
                <div className="container">
                    <main id="content" role="main" className="colfull">
                        <div id="breadcrumbs"></div>
                    
                        <div id="system-message-container">
                        </div>
                        <div className="item-page">
                            <div>
                                <p>MobiAI Solutions is a growth-oriented and information technology firm with highly Talented IT and Management professionals. As a services organization, MobiAI Solution's success is defined by what our employees do and say Our highly skilled and experienced staff enables us to deliver services that exceed client's expectations, reflect client's needs, and maximize their returns.</p>
                                <p>We help our clients create value and architect change through our unique spectrum of IT and management consulting services. We are specialized in providing IT resources, expertise and talent to our customers in a timely and cost effective manner.</p>
                                <p>MobiAI Solutions is an innovative Information Technology Consulting firm that provides superior IT solutions to our clients while offering a rewarding and challenging work experience for our consultants. At MobiAI Solutions, our motive is to provide utmost customer satisfaction by providing cost- effective solutions. We strive hard to achieve excellence in terms of the quality and quantity of work that we do for our clients. We focus on efficiency, flexibility and speed for competitive advantage enabling our customers to tread along the path of success.</p>
                                <p>Our mission is to create business value for our customers, providing the best quality software solutions and services. We specialize in creating new sources of revenue for our clients by helping them develop the Information technology strategies and processes that would resolve their business issues.</p>
                                <p>In a world that is constantly changing, companies are faced with the challenge of continually adapting to a dynamic environment. MobiAI Solutions has the foresight to handle unanticipated needs and challenges, becoming an instrumental part of our customer's success. We realize that understanding each client's needs is essential to providing quality service. The experienced professionals at MobiAI Solutions take the time to understand your business, your approach, and the dictates of your environment. Whether you know exactly what you want or would like informative and innovative guidance, our experienced professionals will work with you every step of the way and our highly skilled consultants will keep your projects safe. MobiAI Solutions is deeply committed and will not stop until all needs have been addressed and dealt with to your complete satisfaction.</p>
                                <h3>What We Do</h3>
                                <p>MobiAI Solutions delivers tailored IT services to match the needs of our customers from all around the globe. Our strategically designed IT services assure high quality, reliable and cost-effective solutions to your business needs. We help our customers achieve long-term success by providing cutting edge technology, relevant strategy, and innovative solutions. Our team of motivated IT experts believe in constantly exploring and innovating new frontiers of the IT field so that we can deliver the best support to our clients. As industry leaders, we introduced offshore development centers, pioneered “follow the sun” development and support frameworks, ensuring compressed delivery timeframes. With our expertise in the IT field and our steadfast pursuit of providing the best solution to our customers, we have today partnered with several reputed organizations in the world.</p>
                                <h3>Our Mission</h3>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div className="wpb_text_column">
                                        <ul className="style_3">
                                            <li>Deliver Responsible Client Services</li>
                                            <li>Provide Employees with an Attractive Working Environment</li>
                                            <li>Maintain High Ethical Standards</li>
                                            <li>Drive Continuous Improvement</li>
                                        </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div className="wpb_text_column">
                                        <ul className="style_3">
                                            <li>Ensure complete security</li>
                                            <li>Provide Safety &amp; Security to Employees</li>
                                            <li>Maintain highly efficient processes</li>
                                            <li>Be an Active Community Partner</li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>

    )
}

export default About

import React, {useState} from 'react'
import "./Navbar.css"
import {Link} from 'react-router-dom'

const Navbar=({companylogo}) =>{

    const [isMobile, setIsMobile] = useState(false);
    return (
        <nav className="navbar justify-content-center justify-content-xl-between">
            <div className="logo">
                <Link to="/" className="">
                    <img src={companylogo} alt="Logo" />
                </Link>
            </div>
            <ul className={isMobile?"nav-links-mobile":"nav-links"}
            onClick={()=>setIsMobile(false)}>
                <Link to="/" className="home nav-item">
                    <li>Home</li>
                </Link>
                <Link to="/about" className="about nav-item">
                    <li>About Us</li>
                </Link>
                <Link to="/services" className="services nav-item">
                    <li>Services</li>
                </Link>
                <Link to="/careers" className="Careers nav-item">
                    <li>Careers</li>
                </Link>
                <Link to="/contact" className="contact nav-item">
                    <li>Contacts</li>
                </Link>
            </ul>

            <button className="mobile-menu-icon" onClick={()=>setIsMobile(!isMobile)}>
                {isMobile ? <i className="fas fa-times"></i>: <i className="fas fa-bars"></i>}
            </button>   
        </nav>
    )
}

export default Navbar

import React from 'react'

const  Footer=({companylogo})=> {
    return (

            <div id="footer">
		        <div className="container">
			        <div className="footer_widgets">
				        <div className="row">
							<div className="moduletable">
                                <div className="custom">
                                    <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                        <p><img src={companylogo}  alt=""/></p>
                                     </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                        <p>MobiAI Solutions<br/>  7600 Chevy Chase Dr, Suite 300 <br/> Austin<br/> Texas 78752<br/>+1-346-629-5350</p>
                                    </div>
                                </div>
		                    </div>
	
				        </div>
			        </div>
		        </div>
                <div className="copyright_row">
                    <div className="container">
                        <div className="copyright_row_wr">
                            <div className="copyright">Copyright © {(new Date().getFullYear())} MobiAI Solutions.</div>
                        </div>
                    </div>
                </div>
            </div>
    )
};

export default Footer

import React, { useState } from "react";
import backGroundAbout from "../assets/about-banner.jpg";
import './Contact.css';

const Contact =()=> {
    const bgStyles = {
        backgroundImage: `url(${backGroundAbout})`,
    }


    const [status, setStatus] = useState("Submit");

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    
 

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");
        const { name, email,phone, message } = e.target.elements;
        let details = {
            name: name.value,
            email: email.value,
            phone: phone.value,
            message: message.value,
        };
        let response = await fetch("http://localhost:5000/contact", {
        method: "POST",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(details),
        });
        setStatus("Submit");
        let result = await response.json();
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        console.log(result);
    };


    return (
        <div id="maincontent">
            <div id="inner-banner margin-tp-btm-50">
                <div className="moduletable">
                    <div className="custom">
                        <div className="page_title about-banner" style={bgStyles}>
                        <div className="container">
                            <h1>Contact Us</h1>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="maincontent">
                <div className="container">
                    <main id="content" role="main" className="colfull">
                        <div id="breadcrumbs"></div>
                        <div id="system-message-container">
                        </div>
                        <div className="fc_page">
                            <div className="fc_outer">
                                <form name="fc_form" className="fc_form" onSubmit={handleSubmit}>
                                    <div className="fc_line">
                                        <label htmlFor="name" className="fc_left">Name:</label>
                                        <input type="text" id="name" name ="name" className="fc_input" placeholder="Enter your full name" value={name} required onChange={e=>setName(e.target.value)} />
                                    </div>
                                    <div className="fc_line">
                                        <label htmlFor="email" className="fc_left">Email:</label>
                                        <input type="email" id="email" name ="email" className="fc_input" placeholder="Enter your email address" value={email} required onChange={e=>setEmail(e.target.value)}/>
                                    </div>
                                    <div className="fc_line">
                                        <label for="phone" className="fc_left">Phone</label>
                                        <input type="text" className="fc_input" name="phone" maxlength = "12" placeholder="Enter phone number" id="phone" value={phone} onChange={e=>setPhone(e.target.value)} />
                                    </div>
                                    <div className="fc_line">
                                        <label htmlFor="message" className="fc_left fc_textarea">Message:</label>
                                        <textarea id="message" name ="message" rows="3" className="fc_input" value={message} required onChange={e=>setMessage(e.target.value)} />
                                    </div>
                                    <div className="fc_line fc_send">
                                        <button type="submit" className="fc_button" id="send_button" name="send_button">{status}</button>
                                    </div>
                                </form>
 
                            </div>

                            <div className="fc_after">
                                <h3>Address</h3>
                               
                                <p>
                                    MobiAI Solutions<br/> 7600 Chevy Chase Dr, Suite 300 <br/> Austin<br/> Texas 78752<br/>+1-346-629-5350<br/><span id="cloakeb13f820ad9e437cd502d4a307b8e553"><a href="mailto:info@mobiais.com">info@mobiais.com</a></span>
                                </p>
                            </div>


                        </div>
                    </main>
                </div>
            </div>

            
        </div>
    )
}

export default Contact


/*
<script type="text/javascript">
    /*
    document.getElementById('cloakeb13f820ad9e437cd502d4a307b8e553').innerHTML = '';
    var prefix = '&#109;a' + 'i&#108;' + '&#116;o';
    var path = 'hr' + 'ef' + '=';
    var addyeb13f820ad9e437cd502d4a307b8e553 = '&#105;nf&#111;' + '&#64;';
    addyeb13f820ad9e437cd502d4a307b8e553 = addyeb13f820ad9e437cd502d4a307b8e553 + '&#105;tv&#97;l&#105;&#97;nt' + '&#46;' + 'c&#111;m';
    var addy_texteb13f820ad9e437cd502d4a307b8e553 = '&#105;nf&#111;' + '&#64;' + '&#105;tv&#97;l&#105;&#97;nt' + '&#46;' + 'c&#111;m';document.getElementById('cloakeb13f820ad9e437cd502d4a307b8e553').innerHTML += '<a ' + path + '\'' + prefix + ':' + addyeb13f820ad9e437cd502d4a307b8e553 + '\'>'+addy_texteb13f820ad9e437cd502d4a307b8e553+'<\/a>';
</script>   
    */

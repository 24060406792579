import React from 'react'
import backGroundAbout from "../assets/services-banner.jpg";

const Services =() =>{

    const bgStyles = {
        backgroundImage: `url(${backGroundAbout})`,
    }

    return (
        <div id="maincontent">
            <div id="inner-banner">
                <div className="moduletable">
                    <div className="custom">
                        <div className="page_title services-banner" style={bgStyles}>
                            <div className="container">
                            <h1>Services</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="maincontent">
                <div className="container">
                    <main id="content" role="main" className="colfull">
                        <div id="breadcrumbs"></div>
                        <div id="system-message-container">
                        </div>
                        <div className="item-page">
                            <div>
                            <p>At MobiAI Solutions, our motive is to provide utmost customer satisfaction by providing cost- effective solutions. We strive hard to achieve excellence in terms of the quality and quantity of work that we do for our clients. We focus on efficiency, flexibility and speed for competitive advantage enabling our customers to tread along the path of success.</p>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <h3>Consulting Services</h3>
                                        <p>Our consulting services range from creating business critical applications to integrating hardware &amp; software and managing all aspects of the IT solutions. MobiAI Solutions consultants have expertise in a range of technical areas like Data Management, Data Warehousing and Business Intelligence, Enterprise Content Management and Portal technologies, application programming, etc. Our professionals, many of whom have global experience, work with client employees at all levels of their organization, designing, developing, testing, deploying and supporting software solutions, using the right enabling technologies to support new ways of doing business.</p>
                                        <p>Because of our broad bandwidth within the staffing industry, MobiAI Solutions Source Group offers its clients a comprehensive array of service options. We work closely with our clients to determine their specific needs to ensure that their requests are matched with the very best talent in the market place.</p>
                                        <h3>Software Development</h3>
                                        <p>Custom application development by MobiAI Solutions Technology Center allows our customers to focus on their core competencies while benefiting from world-class development services. We provide managed software development solutions to our clients who want a complete, reliable, and cost-effective path to application development.</p>
                                        <p>MobiAI Solutions has built experienced &amp; high-performance software project teams fine-tuned to keep the software development costs low and the quality high. We employ time-tested practices that result in:</p>
                                        <div className="wpb_text_column">
                                            <ul className="style_3">
                                            <li><strong>Price control</strong>: cost overruns are eliminated.</li>
                                            <li><strong>High visibility</strong>: production-grade functionality is delivered every two weeks!</li>
                                            <li><strong>Predictability</strong>: schedules can be trusted!</li>
                                            <li><strong>Reliability</strong>: high productivity is a natural result of high quality!</li>
                                            </ul>
                                        </div>
                                        <p>At MobiAI Solutions, our Technology Center utilizes the best of breed processes which results in successful on-time and above expectation delivery solutions. Stringent quality procedures, along with benchmarked practices, and experienced delivery skills help clients get maximum return on their IT spending. MobiAI Solutions established an application framework which enables its customer's continuous improvement.</p>
                                        <p>MobiAI Solutions application processes include:</p>
                                        <div className="wpb_text_column">
                                            <ul className="style_3">
                                            <li>Business Planning and Relationship (BPR) process</li>
                                            <li>Feasibility/Requirements processes</li>
                                            <li>Prototype demo processes</li>
                                            <li>Design Processes</li>
                                            <li>Feature Driven Development Cycle (FDDC) process</li>
                                            <li>System Testing processes</li>
                                            <li>Launch / Production processes</li>
                                            <li>Continuous support to the application</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <h3>Staffing Services</h3>
                                        <p>Organizations today are not only challenged by the constantly changing and emerging technologies, but are also confronted with difficulties in acquiring adequate human resources to meet the dynamics of the different IT Project initiatives. It becomes imperative to any business organization to utilize the most cost-effective ways of acquiring talent for immediate and long-term projects with a specific or niche skill set.</p>
                                        <p>Since our inception, MobiAI Solutions has been offering IT consulting services to the clients which enable them to maximize their IT investment and that has made us extremely competent enough to work on any IT consulting project for any industry domain. We offer world-class business solutions leveraging widely accepted strategies and technologies on different platforms and these services come at competitive rates. We design end to end business solutions from conceptualization to implementation as per the client's requirements.</p>
                                        <h3>Corporate Skills</h3>
                                        <p>At MobiAI Solutions, We provide best hands on online training with real time examples to make sure that the participants are able to handle real time scenarios. We offer the training in the below technologies.</p>
                                        <div className="wpb_text_column">
                                            <ul className="style_3">
                                            <li>Microsoft .NET Framework 4.0/3.5/2.0</li>
                                            <li>Quality Assurance (QA)</li>
                                            <li>JAVA / J2EE</li>
                                            <li>Load Runner</li>
                                            <li>QTP, Informatica</li>
                                            <li>SQL Server BI</li>
                                            <li>Sharepoint 2010/ 2007</li>
                                            <li>Business Objects(BO)</li>
                                            </ul>
                                        </div>
                                        <p>MobiAI Solutions main area of focus is on developing rich high-performance, high quality, cost-effective client/server, intranet and internet based e-commerce and m-commerce solutions for different industries, using the latest cutting edge technologies.</p>
                                        <p>MobiAI Solutions Strengths is in its Emphasis on quality. Its expertise in use of the right technologies a well defined and standardized software development and delivery mechanism. And in maintaining correct harmony between requirements and deliverables on time and on-budget always.</p>
                                        <p>MobiAI Solutions works in partnership with organisations to design and implement balanced and best practice resourcing strategies that optimise recruitment channels whilst maximising brand value. We create solutions through the provision of refined recruitment processes.</p>
                                        <p>MobiAI Solutions also Specializes in legacy applications on various plat forms. This gains more importance, especially in legacy applications that have negligible documentation. Our exprtise spans all major industries, including: Education domain, manufacturing, distribution, retail, insurance, health care, utilities, software, banking &amp; financial services, and government.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default Services

import React from 'react'
import backGroundAbout from "../assets/careers-banner.jpg";

const Careers=()=> {

    const bgStyles = {
        backgroundImage: `url(${backGroundAbout})`,
    }

    return (
        <div id="maincontent">
            <div id="inner-banner">
                <div className="moduletable">
                    <div className="custom">
                        <div className="page_title careers-banner" style={bgStyles}>
                        <div className="container">
                            <h1>Careers</h1>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="maincontent">
                <div className="container">
                    <main id="content" role="main" className="colfull">
                        <div id="breadcrumbs"></div>
                        <div id="system-message-container">
                        </div>
                        <div className="item-page">
                            <div>
                                <p>Our vision is to become leading global technology firm offering complete spectrum of Software Services. We offer you challenging projects in advanced and emerging technologies that are next stage in Enterprise Software. If you are looking for an opportunity to join a rapidly growing software services firm, contact us.</p>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default Careers

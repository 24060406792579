import './App.css';
import Navbar from "./components/Navbar/Navbar"
import Home from "./components/Home"
import About from "./components/About"
import Services from "./components/Services"
import Careers from "./components/Careers"
import Contact from "./components/Contact"
import logo from "./assets/mobiai_new.png"
//import mobiailogo from "./assets/mobiaisolutionsnewlogo.svg"
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Footer from "./components/Footer"

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar companylogo ={logo}/>
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/about" component={About}></Route>
          <Route path="/services" component={Services}></Route>
          <Route path="/careers" component={Careers}></Route>
          <Route path="/contact" component={Contact}></Route>
        </Switch>
        <Footer companylogo ={logo}/>
      </div>
    </Router>
  );
}

export default App;
